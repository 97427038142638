import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  format, subWeeks, isValid, parseISO,
} from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import IcomoonReact from 'icomoon-react';
import { usePopperTooltip } from 'react-popper-tooltip';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import 'react-popper-tooltip/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStartDateCal, setEndDateCal, setWeekRange, setIsChartOpaque,
} from '../../ducks/habitTrackingDashboardActions';

const CustomDateRangePicker = () => {
  const dispatch = useDispatch();
  const today = new Date();

  // Fetching Redux state values
  const reduxStartDate = useSelector((state) => state.habitTrackingDashboard.data.startDate);
  const reduxEndDate = useSelector((state) => state.habitTrackingDashboard.data.endDate);
  const reduxWeekRange = useSelector((state) => state.habitTrackingDashboard.data.weekRange);

  // Local state for dates and range
  const [startDate, setStartDate] = useState(reduxStartDate ? parseISO(reduxStartDate) : subWeeks(new Date(), 1));
  const [endDate, setEndDate] = useState(reduxEndDate ? parseISO(reduxEndDate) : today);
  const [selectedRange, setSelectedRange] = useState(reduxWeekRange || '1W');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  registerLocale('en-GB', enGB);

  // Tooltip state using react-popper
  const {
    getTooltipProps, setTooltipRef, setTriggerRef, visible,
  } = usePopperTooltip({
    placement: 'bottom-end',
    trigger: 'hover',
  });

  // Adjust chart opacity based on tooltip or calendar state
  useEffect(() => {
    dispatch(setIsChartOpaque(visible || isCalendarOpen));
  }, [visible, isCalendarOpen, dispatch]);

  // Calculate the start date based on the selected range and end date
  const calculateStartDate = (end, range) => subWeeks(end, parseInt(range.replace('W', '')) || 1);

  // Handle selection of range (1W, 2W, etc.)
  const handleRangeSelect = (range) => {
    const newStartDate = calculateStartDate(endDate, range);
    setStartDate(newStartDate);
    setSelectedRange(range);

    dispatch(setStartDateCal(format(newStartDate, 'yyyy-MM-dd')));
    dispatch(setEndDateCal(format(endDate, 'yyyy-MM-dd')));
    dispatch(setWeekRange(range));
  };

  // Handle date range selection in DatePicker
  const handleDateChange = (dates) => {
    const [start, end] = dates;

    if (!isValid(start) || start > today) {
      return;
    }

    const newEnd = end || start;
    const newStart = calculateStartDate(newEnd, selectedRange);

    setStartDate(newStart);
    setEndDate(newEnd);

    dispatch(setStartDateCal(format(newStart, 'yyyy-MM-dd')));
    dispatch(setEndDateCal(format(newEnd, 'yyyy-MM-dd')));
  };

  // Handle calendar open/close state
  const handleCalendarOpen = () => setIsCalendarOpen(true);
  const handleCalendarClose = () => setIsCalendarOpen(false);

  // Custom input component for the date picker button
  const CustomInput = ({ value, onClick }) => (
    <button className='custom-input' onClick={onClick}>
      <span>{isValid(endDate) ? format(endDate, 'MMM dd, yyyy') : 'Select date'}</span>
    </button>
  );

  // Highlight days based on selected range and week logic
  const dayClassName = (date) => {
    if (!isValid(date) || !isValid(startDate) || !isValid(endDate)) return 'no-border';

    const isInRange = date >= startDate && date <= endDate;
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)

    if (isInRange) {
      if (dayOfWeek === 1) { // Monday
        return 'rounded-left';
      } if (dayOfWeek === 0) { // Sunday
        return 'rounded-right';
      }
      return 'no-border';
    }

    return 'no-border';
  };

  const isEndDateSunday = endDate && endDate.getDay() === 0;

  // Custom header component for DatePicker
  const CustomHeader = ({
    date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled,
  }) => (
    <div className='custom-header'>
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <IcomoonReact iconSet={iconSet} color='#FFA500' size={22} icon='left-arrow' />
      </button>
      <span>{isValid(date) ? format(date, 'MMMM yyyy') : ''}</span>
      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <IcomoonReact iconSet={iconSet} color='#FFA500' size={22} icon='right-arrow' />
      </button>
    </div>
  );

  return (
    <div className='date-range-picker'>
      <div ref={setTooltipRef} className='range-buttons-container'>
        {['1W', '2W', '4W', '8W', '12W'].map((range, index) => (
          <React.Fragment key={range}>
            <button
              className={`range-button ${selectedRange === range ? 'selected' : ''}`}
              onClick={() => handleRangeSelect(range)}
            >
              {range}
            </button>
            {index < 4 && <span className='separator' />}
          </React.Fragment>
        ))}
      </div>
      <div className='picker-with-info'>
        <DatePicker
          selected={endDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={1}
          customInput={<CustomInput />}
          renderDayContents={(day) => <span className='custom-day'>{day}</span>}
          calendarClassName='custom-calendar'
          dayClassName={dayClassName}
          renderCustomHeader={CustomHeader}
          maxDate={today}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
          popperPlacement='bottom'
          locale='en-GB'
          popperModifiers={{
            flip: {
              behavior: ['bottom'],
            },
            preventOverflow: {
              enabled: true,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {isEndDateSunday ? (
            <div className='custom-footer'>
              <div className='footer-icon'>
                <IcomoonReact iconSet={iconSet} size={16} icon='calendar' color='#D2A739' />
              </div>
              <div className='footer-text'>
                Data collection starts on Monday and is weekly based, ending on a Sunday.
              </div>
            </div>
          ) : (
            <div className='custom-footer'>
              <div className='footer-icon'>
                <IcomoonReact iconSet={iconSet} size={16} icon='warning' color='#D2A739' />
              </div>
              <div className='footer-text'>
                Selection is ongoing, check back on Sunday for completed data.
              </div>
            </div>
          )}
        </DatePicker>
        <div ref={setTriggerRef} className='info-icon'>
          <IcomoonReact iconSet={iconSet} size={18} icon='info' color='#9E9E9E' />
        </div>
        {visible && (
          <div {...getTooltipProps({ className: 'tooltip-container' })}>
            {isValid(startDate) && isValid(endDate) ? (
              <div className='tooltip-date-container'>
                <div>{format(startDate, 'MMM dd, yyyy')}</div>
                <span>→</span>
                <div className='datepicker-input-box end-date'>{format(endDate, 'MMM dd, yyyy')}</div>
              </div>
            ) : (
              'Invalid date range'
            )}
          </div>
        )}
      </div>
      <style>
        {`
      .date-range-picker {
        font-family: 'Nunito Sans', sans-serif;
        display: flex;
        align-items: center;
      }
      
      @media (max-width: 968px) {
        .date-range-picker {
          margin-top: 10px;
        }
      }
      .range-buttons-container {
        display: flex;
        align-items: center;
        margin-right: 16px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        background-color: #FFFFFF;
      }
      
      .range-button {
        padding: 8px 14px;
        background-color: transparent;
        color: #9E9E9E;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        border: none;
        transition: all 0.2s ease;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }
      
      .range-button.selected {
        color: #F60;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }
      
      .range-button:hover {
        color: #FFA500;
      }
      
      .separator {
        width: 1px;
        height: 24px;
        background-color: #D0D5DD;
      }
      
      .custom-input {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 14px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        font-weight: 700;
        transition: all 0.2s ease;
        color: #F60;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }
      
      .custom-input:hover {
        background: #F60;
        color: #FFFFFF;
      }
      
      .picker-with-info {
        display: flex;
        align-items: center;
      }
      
      .custom-calendar {
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        position: relative;
      }
      
      .custom-day {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      
      .rounded-left {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }
      
      .rounded-right {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
      
      .no-border {
        border-radius: 0px !important;
      }
      
      .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range),
      .react-datepicker__day--in-range {
        background-color: #F9F5FF;
        color: #7F56D9;
      }
      
      .react-datepicker__day--selected,
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        background-color: #7F56D9;
        color: #FFA500 !important;
        font-weight: bold !important;
      }
      
      .react-datepicker__day {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: 16px !important;
        padding: 15px 10.5px 10.5px 10.5px;
        width: 40px !important;
        margin: 0 !important;
      }
      
      .react-datepicker__header {
        background-color: #FFFFFF;
        border-bottom: none;
      }
      
      .react-datepicker__current-month {
        font-size: 16px;
        font-weight: 600;
        color: #101828;
      }
      
      .react-datepicker__day-name {
        color: #667085;
        width: 40px !important;
        margin: 0 !important;
      }
      
      .custom-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        font-weight: 700;
        font-size: 19px;
      }
      
      .custom-header button {
        background: none;
        border: none;
        cursor: pointer;
      }
      
      .custom-header button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      
      .custom-header span {
        font-weight: 600;
        color: #101828;
      }
      
      .datepicker-input-box {
        color: #F1F6FF;
      }
      
      .end-date {
        color: #F60 !important;
        font-weight: 700;
      }
      
      .react-datepicker {
        border: none;
        box-shadow: #f2f4f7 0px 0px 10px 6px;
        font-family: 'Nunito Sans', sans-serif;
        width: 350px;
        height: 460px;
      }
      
      .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--range-start) {
        z-index: -1 !important;
      }
      
      .react-datepicker__day--keyboard-selected {
        background-color: transparent !important;
        color: #000 !important;
      }
      
      .react-datepicker__day:hover {
        background-color: #F1F6FF !important;
      }
      
      .react-datepicker__day--in-range {
        background-color: #F1F6FF !important;
        color: #101828 !important;
        z-index: 5 !important;
        margin: 0 !important;
        width: 40px !important;
        margin-top: 5px;
        font-weight: bold;
      }
      
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        color: #FFA500 !important;
        position: relative !important;
        z-index: 1 !important;
      }
      
      .react-datepicker__day--range-start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
        left: 3px !important;
      }
      
      .react-datepicker__day--range-end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
        left: 0px !important;
        right: 10px !important;
      }
      
      .react-datepicker__day--range-start::before,
      .react-datepicker__day--range-end::before {
        content: "";
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0px;
        z-index: -1;
      }
      
      .react-datepicker__day--range-start::before {
        margin-left: 0px;
        transform: translate(1px, -50%);
      }
      
      .react-datepicker__day--range-end::before {
        margin-left: 13px;
        transform: translate(-10px, -50%);
      }
      
      .react-datepicker__day--in-selecting-range {
        background-color: #f2f4f7 !important;
        color: #101828;
        border-radius: 50% !important;
      }
      
      .react-datepicker__day-disabled {
        color: #ccc;
        pointer-events: none;
      }
      
      .react-datepicker__day--outside-month {
        color: #888;
      }
      
      .custom-footer {
        position: absolute;
        bottom: -75px;
        width: 100%;
        text-align: center;
        padding: 10px;
        background: #FFFFFF;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 10px 6px -6px #f2f4f7, 10px 0px 6px -6px #f2f4f7, -10px 0px 6px -6px #f2f4f7;
        padding-bottom: 30px;
      }
      
      .footer-icon {
        margin-right: 8px;
      }
      
      .footer-text {
        color: #9E9E9E;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 19.5px */
        letter-spacing: -0.13px;
        width: 90%;
        margin-top: 7px;
      }
      
      .tooltip-container {
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        padding: 8px 12px;
        border-radius: 8px;
        font-size: 14px;
        transform: translate(-43px, 61px) !important;
        color: #9E9E9E;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }
      
      .tooltip-date-container {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      .info-icon {
        margin-left: 8px;
        cursor: pointer;
        border: 1px solid #9E9E9E;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        border: 2px solid #9E9E9E;
      }
      
      .info-icon svg {
        color: #9E9E9E;
        stroke-width: 2px;
      }
      
      .react-datepicker__month-container {
        width: 100%;
      }
      `}
      </style>
    </div>
  );
};

export default CustomDateRangePicker;
