/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { BoxShadow, TransitionGlobal } from '../../../shared/GlobalStyles';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';
import { setRecoveryRadarActiveModal } from '../ducks/RecoveryRadarActions';

const Container = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 10px 5px 0px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
`;

const DataContainer = styled('div')`
  min-width: 250px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: ${BoxShadow};
  flex: 1;
  margin: 10px;
`;

const CardTitle = styled('div')`
  align-items: center;
  display: flex;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 700;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`;

const InfoWrapper = styled('div')`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border: 2px solid #9E9E9E;
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
    :hover {
      opacity: ${(props) => (props.disabled ? '1' : '.7')};
    }
  transition: ${TransitionGlobal};
`;

const DataPercentageContainer = styled('div')`
`;

const CardData = styled('div')`
  display: flex;
  align-items: center;
  font-size: 50px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  justify-content: center;
`;

const PercentageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -28px;

  svg {
    fill: ${(props) => (props.color ? `${props.color}` : '#9E9E9E')};
  }
`;

const Percentage = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: 400;
`;

const CardsContainer = () => {
  const cardData = useSelector((state) => state.recoveryRadar.data.cardData);

  const hasData = cardData?.length > 0;

  const reportedData = hasData && cardData.find((item) => item.title === 'Reported');
  const severityData = hasData && cardData.find((item) => item.title === 'Avg. Severity');
  const reviewData = hasData && cardData.find((item) => item.title === 'Athletes To Review');

  const dispatch = useDispatch();

  const handleInfoClick = (card) => {
    if (hasData) {
      dispatch(setRecoveryRadarActiveModal(card));
      // KPI tracking
      dashboardTracker('Recovery Radar', 'Overview - Cards', `${card} legend clicked`);
    }
  };

  return (
    <Container isBlurred={!hasData}>
      <DataContainer>
        <CardTitle>
          Reported
          <InfoWrapper disabled={!hasData} onClick={() => handleInfoClick('Reported-card')}>
            <IcomoonReact
              color='#9E9E9E'
              icon='info'
              iconSet={iconSet}
              size={12}
            />
          </InfoWrapper>
        </CardTitle>
        <DataPercentageContainer>
          <CardData>
            {reportedData?.value || '0'}
          </CardData>
          <PercentageContainer color={reportedData?.alertColor || null}>
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon={
                  reportedData?.percentageChange > 0 ? 'alt-up-arrow'
                    : reportedData?.percentageChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
            />
            <Percentage>
              {reportedData?.percentageChange || '0.00'}
              %
            </Percentage>
          </PercentageContainer>
        </DataPercentageContainer>
      </DataContainer>
      <DataContainer>
        <CardTitle>
          Avg. Severity
          <InfoWrapper disabled={!hasData} onClick={() => handleInfoClick('Severity-card')}>
            <IcomoonReact
              color='#9E9E9E'
              icon='info'
              iconSet={iconSet}
              size={12}
            />
          </InfoWrapper>
        </CardTitle>
        <DataPercentageContainer>
          <CardData>
            {severityData?.value || '0'}
          </CardData>
          <PercentageContainer color={severityData?.alertColor || null}>
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon={
                  severityData?.percentageChange > 0 ? 'alt-up-arrow'
                    : severityData?.percentageChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
            />
            <Percentage>
              {severityData?.percentageChange || '0.00'}
              %
            </Percentage>
          </PercentageContainer>
        </DataPercentageContainer>
      </DataContainer>
      <DataContainer>
        <CardTitle>
          Athletes to Review
          <InfoWrapper disabled={!hasData} onClick={() => handleInfoClick('Review-card')}>
            <IcomoonReact
              color='#9E9E9E'
              icon='info'
              iconSet={iconSet}
              size={12}
            />
          </InfoWrapper>
        </CardTitle>
        <DataPercentageContainer>
          <CardData>
            {reviewData?.value || '0'}
          </CardData>
        </DataPercentageContainer>
      </DataContainer>
    </Container>
  );
};

export default CardsContainer;
