/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
} from 'recharts';

import Spinner from '../../../shared/_TBComponents/Button/Spinner';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';
import { fetchOverviewData, setDateRange } from '../ducks/RecoveryRadarActions';

const ChartContainer = styled('div')`
  width: 100%;
  height: ${(props) => (props.isLoading ? '355px' : '90%')};
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};

  .custom-tooltip {
    background-color: #FFFFFF;
    padding: 8px 16px;
    border: solid 1px #EEEEEE;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 250px;
    justify-content: flex-start;
    font-family: 'Nunito Sans';
    font-size: 13px;
    font-weight: normal;
  }
`;

const DailySelector = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -29px;
  position: relative;
  z-index: 2;
`;

const DailyOption = styled('button')`
  border: none;
  ont-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
`;

const DailySpacer = styled('span')`
  color: #9E9E9E;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin: 0px 5px;
  cursor: default;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p style={{ fontWeight: 'bold' }}>{label}</p>
        {payload.map((dataPoint) => (
          <p key={dataPoint.name} style={{ color: dataPoint.formatter || dataPoint.color }}>
            {`${dataPoint.name}: `}
            <span style={{ color: '#444444' }}>{dataPoint.value}</span>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const RROverviewChart = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeFiltersOverview = useSelector((state) => state.recoveryRadar.data.activeFiltersOverview);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const isOverviewDataLoading = useSelector((state) => state.recoveryRadar.ui.isOverviewDataLoading);
  const chartData = useSelector((state) => state.recoveryRadar.data.chartData);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);

  const hasData = chartData.length > 0;
  const data = hasData && [...chartData];

  // Order and format the dates correctly between monthly/daily
  const formattedData = data && data.map((obj) => ({
    ...obj,
    date: moment(obj.date).format('MMM DD'),
  }));

  const dispatch = useDispatch();

  // Create dummy/default data for the no-data view of graph
  const defaultData = [];
  for (let i = 6, n = 0; i >= n; i--) {
    if (i !== 0) {
      const day = {
        date: moment(selectedDateData).subtract(i, 'day').format('MMM DD'),
        exerData: 0,
      };
      defaultData.push(day);
    } else {
      const day = {
        date: moment(selectedDateData).format('MMM DD'),
        exerData: 0,
      };
      defaultData.push(day);
    }
  }

  const handleDateRangeClick = (range) => {
    // KPI tracking
    dashboardTracker('Recovery Radar', 'Overview Chart - Daily/Monthly Picker', `${range === 7 ? '7 day' : '28 day'} data range selected`);
    batch(() => {
      dispatch(setDateRange(range));
      dispatch(fetchOverviewData(
        accountCode,
        group?.id,
        moment(selectedDateData).subtract(range - 1, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        [],
        [],
      ));
    });
  };

  return (
    <>
      {isOverviewDataLoading ? (
        <ChartContainer isLoading={isOverviewDataLoading}>
          <Spinner />
        </ChartContainer>
      ) : (
        <ChartContainer isBlurred={!hasData}>
          <DailySelector>
            <DailyOption
              disabled={!hasData || dateRange === 7}
              isActive={dateRange === 7}
              onClick={() => handleDateRangeClick(7)}
            >
              7D
            </DailyOption>
            <DailySpacer> | </DailySpacer>
            <DailyOption
              disabled={!hasData || dateRange === 28}
              isActive={dateRange === 28}
              onClick={() => handleDateRangeClick(28)}
            >
              28D
            </DailyOption>
          </DailySelector>
          {hasData ? (
            <>
              <ResponsiveContainer id='printableDiv' minHeight={420} width='100%'>
                <LineChart data={formattedData}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis angle={315} dataKey='date' height={50} stroke='#9E9E9E' textAnchor='end' />
                  <YAxis
                    label={
                        {
                          angle: -90,
                          fill: '#666666',
                          position: 'insideLeft',
                          style: { textAnchor: 'middle' },
                          value: 'Num of Reported',
                        }
                      }
                    stroke='#9E9E9E'
                    width={60}
                  />
                  {activeFiltersOverview.map((item, i) => (
                    <Line
                      key={`bodyPartLine_${i}`}
                      connectNulls
                      name={item.name}
                      type='linear'
                      dataKey={`bodyParts[${item.name}].value`}
                      stroke={formattedData[0]?.bodyParts[item.name]?.color || ''}
                    />
                  ))}
                  <Tooltip content={<CustomTooltip />} />
                  <Legend verticalAlign='top' height={30} />
                </LineChart>
              </ResponsiveContainer>
            </>
          ) : (
            <ResponsiveContainer minHeight={420} width='100%'>
              <LineChart
                data={defaultData}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis angle={315} dataKey='date' height={50} stroke='#9E9E9E' textAnchor='end' />
                <YAxis
                  domain={[0, 30]}
                  label={{
                    angle: -90,
                    fill: '#666666',
                    position: 'insideLeft',
                    style: { textAnchor: 'middle' },
                    value: 'Num of Reported',
                  }}
                  stroke='#9E9E9E'
                />
                <Legend verticalAlign='top' height={30} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default RROverviewChart;
