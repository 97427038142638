import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import CompletionFilter from './CompletionFilter';
import SelectBox from '../../../../shared/_TBComponents/SelectBox';
import {
  fetchAthletesHabitDashboard,
  fetchGroups,
  fetchOverview,
  fetchGroupHabits,
  fetchHabitsForAccount,
  resetOverviewData,
  setIsLegendInfoModalShowing,
  setSelectedPerformanceDropdownOptions,
  setHabits,
} from '../../ducks/habitTrackingDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';
import HTOverviewChart from './HTOverviewChart';
import MetricsContainers from './MetricsContainers';
import CustomDatePicker from './CustomDatePicker';
import LegendInfoModal from './LegendInfoModal';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
    transition: background-color 0.3s ease, border 0.3s ease;
    cursor: pointer;
  }
`;

const LegendTitle = styled('div')`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  color: #444;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 23px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.46px;
`;

const Subtitle = styled('div')`
  color: #444;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
`;

const LegendAndIcon = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-left: 17px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  align-items: center;
  margin-right: 17px;

  button {
    background: transparent!important;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const InfoTextAndButton = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const HabitInfoText = styled('div')`
  color:  #444;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

// Custom styles for SelectBox components
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#007B55' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 85, 0.25)' : null,
    '&:hover': {
      borderColor: '#007B55',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#E0F7E0' : state.isFocused ? '#F1F1F1' : 'white',
    color: state.isSelected ? '#007B55' : '#444444',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    '&:hover': {
      backgroundColor: '#E0F7E0',
      color: '#007B55',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

const CompletionOverview = () => {
  const dispatch = useDispatch();

  const athleteList = useSelector((state) => state.habitTrackingDashboard.data.athletes);
  const groups = useSelector((state) => state.habitTrackingDashboard.data.groups);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const startDate = useSelector((state) => state.habitTrackingDashboard.data.startDate);
  const endDate = useSelector((state) => state.habitTrackingDashboard.data.endDate);
  const isLegendInfoModalShowing = useSelector((state) => state.habitTrackingDashboard.ui.isLegendInfoModalShowing);
  const selectedPerformanceDropdownOptions = useSelector((state) => state.habitTrackingDashboard.data.selectedPerformanceDropdownOptions);
  const overviewData = useSelector((state) => state.habitTrackingDashboard.data.overviewResults);
  const hasOverviewData = overviewData && Object.keys(overviewData).length > 0;

  const printRef = useRef();
  const prevStartDate = useRef(startDate);
  const prevEndDate = useRef(endDate);

  const [selectedOption, setSelectedOption] = useState(null);
  const [secondSelectBoxOptions, setSecondSelectBoxOptions] = useState([]);
  const [isSecondSelectBoxOpen, setIsSecondSelectBoxOpen] = useState(false);
  const [key, setKey] = useState(0);
  const [previousSecondOption, setPreviousSecondOption] = useState(null);
  const [cachedHabits, setCachedHabits] = useState({});

  const athleteDropdownList = athleteList?.map((athlete) => ({
    name: `${athlete.first} ${athlete.last}`,
    id: athlete.id,
  })) ?? [];

  useEffect(() => {
    if (currentUser) {
      if ((!athleteList || athleteList.length === 0) && (!groups || groups.length === 0)) {
        dispatch(fetchGroups(currentUser.accountCode));
        dispatch(fetchAthletesHabitDashboard(currentUser.accountCode));
      }
    }
  }, [currentUser, athleteList.length, groups.length, dispatch]);

  useEffect(() => {
    if (selectedPerformanceDropdownOptions[0]) {
      handleFirstSelectBoxChange(selectedPerformanceDropdownOptions[0], true);
    }
  }, []);

  useEffect(() => {
    if (selectedPerformanceDropdownOptions[1] && (prevEndDate.current !== endDate || prevStartDate.current !== startDate)) {
      fetchOverviewData(
        selectedPerformanceDropdownOptions[0].name,
        selectedPerformanceDropdownOptions[1].id,
        selectedPerformanceDropdownOptions[1].name,
      );
    }
    prevStartDate.current = startDate;
    prevEndDate.current = endDate;
  }, [endDate, startDate]);

  const fetchHabitsIfNeeded = async (groupType, groupId) => {
    const cacheKey = `${groupType}-${groupId}`;

    // Check if habitsResult is already cached
    if (!cachedHabits[cacheKey]) {
      let habitsResult;

      // Fetch habits based on the group type
      if (groupType === 'Group') {
        habitsResult = await dispatch(fetchGroupHabits(currentUser.accountCode, groupId));
      } else if (groupType === 'Individual') {
        habitsResult = await dispatch(fetchHabitsForAccount(currentUser.accountCode, groupId));
      }

      if (habitsResult) {
        const combinedHabits = [...habitsResult.assignedHabits, ...habitsResult.universalHabits];
        // Extract the habit IDs
        const habitIds = combinedHabits.map((habit) => habit.id);

        // Cache the full habits result
        setCachedHabits((prev) => ({ ...prev, [cacheKey]: habitsResult }));

        return habitIds;
      }
    } else {
      // If cached, retrieve cached result
      const cachedResult = cachedHabits[cacheKey];

      // Combine the habits (assigned and universal) from cached data
      const combinedHabits = [...cachedResult.assignedHabits, ...cachedResult.universalHabits];
      const habitIds = combinedHabits.map((habit) => habit.id);

      dispatch(setHabits(cachedResult));

      return habitIds;
    }
  };

  const fetchOverviewData = async (groupType, groupId) => {
    const habitIds = await fetchHabitsIfNeeded(groupType, groupId);
    dispatch(fetchOverview(currentUser.accountCode, groupType === 'Group' ? 2 : 1, groupId, habitIds, startDate, endDate));
  };

  const handleFirstSelectBoxChange = (option, isInitial = false) => {
    setSelectedOption(option);
    setIsSecondSelectBoxOpen(false);
    if (!isInitial) {
      dispatch(resetOverviewData());
    }

    let newOptions = [];
    if (option.name === 'Group') {
      newOptions = groups.map((g) => ({
        name: g.name,
        id: g.id,
      }));
    } else if (option.name === 'Individual') {
      newOptions = athleteDropdownList;
    }
    setSecondSelectBoxOptions(newOptions);

    if (selectedPerformanceDropdownOptions[1]) {
      dispatch(setSelectedPerformanceDropdownOptions([option, null]));
    }

    if (newOptions.length > 0) {
      setIsSecondSelectBoxOpen(true);
      if (isInitial && selectedPerformanceDropdownOptions[0]) {
        const matchingOption = newOptions.find((opt) => opt.id === selectedPerformanceDropdownOptions[1].id);
        if (matchingOption) {
          handleSecondSelectBoxChange(matchingOption, true, option);
        }
      }
    }

    setKey((prevKey) => prevKey + 1);
  };

  const handleSecondSelectBoxChange = async (option, isInitial = false, firstOption = selectedOption) => {
    if (!firstOption) {
      firstOption = selectedPerformanceDropdownOptions[0];
    }

    if (previousSecondOption?.id === option.id) {
      return;
    }

    setPreviousSecondOption(option);
    dispatch(setSelectedPerformanceDropdownOptions([firstOption, option]));

    if (!isInitial) {
      await fetchOverviewData(firstOption.name, option.id, option.name);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      <DashboardContainer ref={printRef}>
        <OptionsHeader>
          <MenuOptionsContainer>
            <SelectBox
              options={[
                { name: 'Group', id: 1 },
                { name: 'Individual', id: 2 },
              ]}
              onChangeFunction={handleFirstSelectBoxChange}
              width='175px'
              customStyles={customStyles}
              placeholder='Select an option'
              isClearable={false}
              defaultValue={selectedPerformanceDropdownOptions[0] || { name: 'Select an option' }}
            />
            {(isSecondSelectBoxOpen || selectedPerformanceDropdownOptions[1]) && (
              <SelectBox
                key={key}
                options={secondSelectBoxOptions || groups}
                onChangeFunction={handleSecondSelectBoxChange}
                width='175px'
                customStyles={customStyles}
                placeholder={secondSelectBoxOptions.length ? `Select ${selectedOption?.name.toLowerCase()}` : `No ${selectedOption?.name.toLowerCase()} available`}
                isClearable={false}
                defaultValue={selectedPerformanceDropdownOptions[1] || { name: 'Select an option' }}
              />
            )}
            <CompletionFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <CustomDatePicker />
            <Button
              className='printBtn'
              disabled={!hasOverviewData}
              iconOnly
              icon='printer'
              onClick={() => {
                handlePrint();
                dashboardTracker('Consistency Coach', 'Completion Overview - Print', 'Print button clicked');
              }}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <HTOverviewChart />
        {!hasOverviewData && (
          <NoDataModal>
            <ModalTitle>
              {!selectedPerformanceDropdownOptions[0] ? 'Select a Group or Individual' : 'No Data Available'}
            </ModalTitle>
            <ModalMessage>
              {!selectedPerformanceDropdownOptions[0]
                ? 'Select a group or individual using the dropdowns above to get started'
                : 'There is no data available, please adjust your selection above'}
            </ModalMessage>
          </NoDataModal>
        )}
      </DashboardContainer>
      <LegendAndIcon isBlurred={!hasOverviewData}>
        <LegendTitle>
          Current week
          <Subtitle>
            Reminder that the current week is ongoing, check back on Sunday for completed data for this week.
          </Subtitle>
        </LegendTitle>
        <InfoTextAndButton>
          <HabitInfoText>Habit Info</HabitInfoText>
          <Button
            iconOnly
            icon='info'
            customColor='#00000000'
            noBorder
            onClick={() => dispatch(setIsLegendInfoModalShowing(true))}
            rounded
            disabled={false}
          />
        </InfoTextAndButton>
      </LegendAndIcon>
      {isLegendInfoModalShowing && (
        <LegendInfoModal />
      )}
      <MetricsContainers />
    </>
  );
};

export default CompletionOverview;
