import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsLegendInfoModalShowing } from '../../ducks/habitTrackingDashboardActions';

const MetricsContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0px 5px;
  padding-bottom: 10px;

  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};

`;

const MetricContainer = styled('div')`
  min-width: 280px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: ${BoxShadow};
  flex: 1;
  margin: 10px;
  border: 2px solid ${(props) => props.borderColor || '#000'};
  display: flex;
  flex-direction: column;
`;

const TitleAndIcon = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

const MetricTitle = styled('div')`
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9E9E9E;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.13px;
  svg {
    margin-right: 8px;
  }
`;

const MetricFrequency = styled('div')`
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans';
  color: #9E9E9E;
  margin-left: 10px;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.13px;

  svg {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const MetricDataLabel = styled('div')`
  color: #444;
  font-feature-settings: 'liga' off, 'clig' off;
  text-overflow: ellipsis;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
`;

const MetricData = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const CompletionRate = styled('div')`
  color: #444;
  font-feature-settings: 'liga' off, 'clig' off;
  text-overflow: ellipsis;
  font-family: "Nunito Sans";
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 45.1px */
  letter-spacing: -0.82px;
`;

const AssignedLabel = styled('div')`
  margin-top: 5px;
  color: #9E9E9E;
  font-feature-settings: 'liga' off, 'clig' off;
  text-overflow: ellipsis;
  font-family: "Nunito Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.19px;
  display: flex;
  align-items: baseline;
`;

const CompletionPercentage = styled('div')`
  margin-top: auto;
  align-self: flex-end;

  color: #444;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.19px;
`;

const BottomCompletionContainer = styled('div')`
  font-family: 'Nunito Sans';
  display: flex;
  justify-content: space-between;
`;

const AssignedText = styled('div')`
  color: #9E9E9E;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 232%; /* 20.88px */
  margin-left: 5px;
`;

const StyledButton = styled(Button)`
  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const PlaceholderData = [
  {
    habitId: 1, habitName: '', habitCategoryIcon: '', habitCategoryColor: '#ccc', frequency: 0, cadence: 1, completedForDate: 0, assignedForDate: 0, value: '',
  },
  {
    habitId: 2, habitName: '', habitCategoryIcon: '', habitCategoryColor: '#ccc', frequency: 0, cadence: 1, completedForDate: 0, assignedForDate: 0, value: '',
  },
  {
    habitId: 3, habitName: '', habitCategoryIcon: '', habitCategoryColor: '#ccc', frequency: 0, cadence: 1, completedForDate: 0, assignedForDate: 0, value: '',
  },
];

const MetricsContainers = () => {
  const currentWeekData = useSelector(
    (state) => state.habitTrackingDashboard.data.overviewResults.currentWeekData || [],
  );

  const selectedHabits = useSelector((state) => state.habitTrackingDashboard.data.selectedHabits);

  const hasOverviewData = useSelector((state) => {
    const overviewData = state.habitTrackingDashboard.data.overviewResults;
    return overviewData && Object.keys(overviewData).length > 0;
  });

  const dispatch = useDispatch();

  // Filter currentWeekData based on selectedHabits
  const filteredData = currentWeekData.filter((metric) => selectedHabits.includes(metric.habitId));

  // Use filtered data if available, otherwise use PlaceholderData
  const dataToDisplay = filteredData.length > 0 ? filteredData : PlaceholderData;

  return (
    <MetricsContainer isBlurred={!hasOverviewData}>
      {dataToDisplay.map((metric) => (
        <MetricContainer key={metric.habitId} borderColor={metric.habitCategoryColor}>
          <TitleAndIcon>
            <MetricTitle>
              {metric.habitCategoryIcon && (
                <IcomoonReact
                  iconSet={iconSet}
                  size={14}
                  icon={metric.habitCategoryIcon}
                  color='#9E9E9E'
                />
              )}
              {metric.habitName || 'New Habit'}
            </MetricTitle>
            <MetricFrequency>
              {metric.cadence === 1 ? 'Daily' : `${metric.frequency} x weekly`}
              <StyledButton
                iconOnly
                icon='info'
                customColor='#00000000'
                noBorder
                onClick={() => dispatch(setIsLegendInfoModalShowing(true))}
                rounded
                disabled={!hasOverviewData}
              />
            </MetricFrequency>
          </TitleAndIcon>
          <MetricDataLabel>Completion Rate</MetricDataLabel>
          <MetricData>
            <CompletionRate>{metric.completedForDate || '0'}</CompletionRate>
            <BottomCompletionContainer>
              <AssignedLabel>
                {metric.assignedForDate || '0'}
                <AssignedText>Assigned</AssignedText>
              </AssignedLabel>
              <CompletionPercentage>{metric.value || '0%'}</CompletionPercentage>
            </BottomCompletionContainer>
          </MetricData>
        </MetricContainer>
      ))}
    </MetricsContainer>
  );
};

export default MetricsContainers;
