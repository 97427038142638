/* eslint-disable max-len */
// Authored by Josh Beedle
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ColorWhite } from '../../GlobalStyles';
import Wrapper from './Wrapper';

const BannerMessage = styled('div')`
  color: ${(props) => (props.txtColor)};
  font-size: ${(props) => (props.fontSize)};
  font-weight: 400;
`;

const ContactButton = styled('button')`
  background-color: ${ColorWhite};
  border: solid 2px #EEEEEE;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 700;
`;

const InnerWidthContainer = styled('div')`
  display: flex;
  height: 36px;
  justify-content: space-between;
  min-width: ${(props) => (props.minInnerWidth)};
  padding: 6px 16px;
  width: ${(props) => (props.innerWidth)};

  @media only screen and (max-width: 1023px) {
    padding: 15px 0px;
  }
`;

const emptyFunc = () => undefined;

/**
  * @param {String} color1 controls the color for the first half of the gradient
  * @param {String} color2 controls the color for the second half of the gradient
  * @param {Function} handleClick function to execute when contact button is clicked
  * @param {String} height controls the height of the banner
  * @param {String} minHeight controls the min height of the banner
  * @param {Bool} includeButton controls whether or not to display the contact button
  * @param {String} message controls the text displayed in the banner
  * @param {String} txtColor controls the color of the message text
  * @param {String} fontSize controls the size of the message text
  * @param {String} width controls the width of the banner
  * @param {String} innerwidth controls the inner width of the banner/ the max width of the content
  * @param {String} minInnerwidth controls the min inner width of the banner/ the min width of the content inside the banner
*/
const NewDashBanner = ({
  color1,
  color2,
  handleClick,
  height,
  minHeight,
  includeButton,
  message,
  txtColor,
  fontSize,
  width,
  innerWidth,
  minInnerWidth,
}) => (
  <Wrapper
    color1={color1}
    color2={color2}
    height={height}
    minHeight={minHeight}
    width={width}
  >
    <InnerWidthContainer innerWidth={innerWidth} minInnerWidth={minInnerWidth}>
      <BannerMessage txtColor={txtColor} fontSize={fontSize}>
        {message}
      </BannerMessage>
      {includeButton && (
      <ContactButton onClick={handleClick}>
        Contact for more details
      </ContactButton>
      )}
    </InnerWidthContainer>
  </Wrapper>
);

NewDashBanner.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  handleClick: PropTypes.func,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  includeButton: PropTypes.bool,
  message: PropTypes.string,
  txtColor: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  innerWidth: PropTypes.string,
  minInnerWidth: PropTypes.string,
};

NewDashBanner.defaultProps = {
  color1: '#F7B733',
  color2: '#FC4A1A',
  handleClick: emptyFunc,
  height: '95px',
  minHeight: '95px',
  includeButton: false,
  message: 'Dashboard coming soon!',
  txtColor: ColorWhite,
  fontSize: '23px',
  width: 'auto !important',
  innerWidth: '100%',
  minInnerWidth: 'none',
};

export default NewDashBanner;
