/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import Shimmer from 'react-shimmer-effect';
import ResizeObserver from 'react-resize-observer';
import { useReactToPrint } from 'react-to-print';

import { BoxShadow } from '../../../shared/GlobalStyles';
import SelectBox from '../../../shared/_TBComponents/SelectBox';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';
import {
  setGroup,
  setSelectedAthlete,
  setSelectedDateCal,
  setSelectedDateData,
  fetchUsersAssociatedWithGroup,
  fetchUserHistoryData,
  clearAthleteHistoryData,
  setRecoveryRadarActiveModal,
  setPrintCurrentPage,
  fetchOverviewData,
  fetchGroupAnalysisData,
} from '../ducks/RecoveryRadarActions';
import DatePicker from '../../../shared/_TBComponents/DatePicker';
import Filter from '../../../shared/_TBComponents/DashboardFilter';
import Spinner from '../../../shared/components/Spinner/Spinner';
import RRAnalysisHistoryTableNoData from './RRAnalysisHistoryTableNoData';
import RRAnalysisHistoryTable from './RRAnalysisHistoryTable';
import Button from '../../../shared/_TBComponents/Button';
import BodyHeatMap from './BodyHeatMap';
import Text from '../../../shared/components/Text/Text';
import FilterHelper from './filterHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};

  @media print {
    transform: scale(0.60);
    transform-origin: top left;
  }
`;

const TableContainer = styled('div')`
display: flex;
flex-direction: column;
width: 100%;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.containerWidth < 440 && '100%'};
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: ${(props) => props.containerWidth > 320 && 'space-between'};
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
  }
`;

const BodyWrapper = styled('div')`
display: flex;
justify-content: center;
flex-wrap: ${(props) => props.containerWidth < 450 && 'wrap'};
position: relative;
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: ${({ containerHeight }) => `calc(50% - ${containerHeight / 2}px)`};
  max-width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const ShimmerContainer = styled('div')`
  height:${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 4px;
`;

const SpinnerContainer = styled('div')`
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin-top: 54px;
  min-height: 200px;
`;

const TablesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const History = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeFilters = useSelector((state) => state.recoveryRadar.data.activeFilters);
  const activeTypeFilters = useSelector((state) => state.recoveryRadar.data.activeTypeFilters);
  const areaSelectedAverageData = useSelector((state) => state.recoveryRadar.data.areaSelectedAverageData);
  const areaSelectedTableData = useSelector((state) => state.recoveryRadar.data.areaSelectedTableData);
  const athleteList = useSelector((state) => state.recoveryRadar.data.athleteList);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const groups = useSelector((state) => state.recoveryRadar.data.groups);
  const historyAverageData = useSelector((state) => state.recoveryRadar.data.historyAverageData);
  const historyHeatMapData = useSelector((state) => state.recoveryRadar.data.historyHeatMapData);
  const historyTableData = useSelector((state) => state.recoveryRadar.data.historyTableData);
  const isAthleteHistoryDataLoading = useSelector((state) => state.recoveryRadar.ui.isAthleteHistoryDataLoading);
  const isFilterOpen = useSelector((state) => state.recoveryRadar.ui.isFilterOpen);
  const selectedAthlete = useSelector((state) => state.recoveryRadar.data.selectedAthlete);
  const selectedDateCal = useSelector((state) => state.recoveryRadar.data.selectedDateCal);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);
  const isSideBarOpen = useSelector((state) => state.sportsScience.data.isSideBarOpen);
  const isSideBarExpanded = useSelector((state) => state.sportsScience.data.isSideBarExpanded);
  const isAthletesAssociatedWithGroupLoading = useSelector((state) => state.recoveryRadar.ui.isAthletesAssociatedWithGroupLoading);
  const printCurrentPage = useSelector((state) => state.recoveryRadar.data.printCurrentPage);

  const hasAreaSelectedData = areaSelectedTableData?.length > 0;
  const hasHeatMapData = historyHeatMapData?.length > 0;
  const hasHistoryData = historyTableData?.length > 0;

  const filterIds = activeFilters.map((obj) => obj.id);
  const typeIds = activeTypeFilters.map((obj) => obj.id);

  const containerRef = useRef(null);

  const dispatch = useDispatch();

  const [noDataModalContainerHeight, setNoDataModalContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);

  const {
    handleActionButton,
    handleChangeAnalysis,
    handleChangeType,
    handleClear,
    handleClose,
    handleFilter,
    options,
    searchTerm,
    setSearchTerm,
    typeOptions,
  } = FilterHelper();

  const formattedAthleteHistoryBodyMapData = historyHeatMapData?.map((dataObject) => (
    {
      slug: dataObject.bodyPartName,
      intensity: dataObject.value,
      soreness: !dataObject.pain,
      value: dataObject.pain ? `Pain (${dataObject.value})` : `Soreness (${dataObject.value})`,
    }
  ));

  const setSelectedGroup = (option) => {
    /** KPI tracking */
    dashboardTracker('Recovery Radar', 'Athlete History - Group Picker', 'Group selected');
    batch(() => {
      // Set group
      dispatch(setGroup(option));
      // Clear any athlete data
      dispatch(setSelectedAthlete(null));
      dispatch(clearAthleteHistoryData());
      // Fetch athlete list
      dispatch(fetchUsersAssociatedWithGroup(accountCode, option?.id));
      // Fetch all the data
      dispatch(fetchOverviewData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIds,
      ));
    });
  };

  const setAthlete = (option) => {
    // KPI tracking
    dashboardTracker('Recovery Radar', 'Athlete History - Athlete Picker', 'Athlete selected');
    batch(() => {
      dispatch(setSelectedAthlete(option));
      dispatch(fetchUserHistoryData(
        accountCode,
        option.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIds,
      ));
    });
  };

  const setDate = (date) => {
    /** KPI tracking */
    dashboardTracker('Recovery Radar', 'Athlete History - Date Picker', 'Start date selected');
    batch(() => {
      dispatch(setSelectedDateCal(moment(date)));
      dispatch(setSelectedDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(fetchOverviewData(
        accountCode,
        group?.id,
        moment(date).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        group?.id,
        moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        typeIds,
        filterIds,
      ));
      if (selectedAthlete?.id) {
        dispatch(fetchUserHistoryData(
          accountCode,
          selectedAthlete?.id,
          moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
          moment(date).format('YYYY-MM-DD'),
          typeIds,
          filterIds,
        ));
      }
    });
  };

  const formatAthleteList = () => athleteList?.map((athleteObject) => ({
    name: `${athleteObject?.first} ${athleteObject?.last}`,
    id: athleteObject.id,
  }));

  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        .printable-container {
          transform: scale(0.60);
          transform-origin: top left;
        }
      }
    `,
  });

  useEffect(() => {
    if (printCurrentPage) {
      handlePrint();
      batch(() => {
        dispatch(setRecoveryRadarActiveModal(null));
        dispatch(setPrintCurrentPage(false));
      });
    }
  }, [printCurrentPage]);

  useEffect(() => {
    let timeoutId;
    if (containerRef.current) {
      timeoutId = setTimeout(() => {
        const rect = containerRef.current.getBoundingClientRect();
        setContainerWidth(rect?.width);
      }, 400);
    }

    /** Clean up the timeout if dependencies change or component unmounts */
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSideBarExpanded, isSideBarOpen]);

  useEffect(() => {
    if (!selectedAthlete?.id) {
    /** if athlete is cleared from dropdown, clear previous user history data */
      dispatch(clearAthleteHistoryData());
    }
  }, [selectedAthlete]);

  return (
    <>
      <DashboardContainer className='printable-container' ref={containerRef}>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <OptionsHeader>
          <MenuOptionsContainer containerWidth={containerWidth}>
            <SelectBox
              isClearable
              defaultValue={group}
              options={groups}
              onChangeFunction={setSelectedGroup}
              placeholder='Select a Group'
              removeSeparator
              styledProps={{
                position: 'relative',
                margin: '5px 10px 5px 0px',
                height: '40px',
              }}
              width={containerWidth < 440 ? '100%' : '175px'}
            />
            {isAthletesAssociatedWithGroupLoading ? (
              <Shimmer>
                <ShimmerContainer width={containerWidth < 440 ? '100%' : '200px'} height='40px' />
              </Shimmer>
            ) : (
              <SelectBox
                isClearable
                defaultValue={selectedAthlete}
                value={selectedAthlete}
                options={formatAthleteList()}
                onChangeFunction={setAthlete}
                placeholder='Select an Athlete'
                removeSeparator
                styledProps={{
                  position: 'relative',
                  margin: '5px 10px 5px 0px',
                  height: '40px',
                }}
                width={containerWidth < 440 ? '100%' : '175px'}
              />
            )}
            {containerWidth > 680 && (
              <Filter
                actionButtonName='Update'
                activeFilters={activeFilters}
                activeTypeFilters={activeTypeFilters}
                filterTitle='Type'
                handleActionButton={handleActionButton}
                handleChange={handleChangeAnalysis}
                handleChangeType={handleChangeType}
                handleClear={handleClear}
                handleClose={handleClose}
                handleFilter={handleFilter}
                isArrayOfObjects
                isOpen={isFilterOpen}
                isSearchable
                options={options}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                styledPropsWrapper={{
                  marginTop: '6px',
                }}
                subheader='Area Selected'
                typeOptions={typeOptions}
              />
            )}
          </MenuOptionsContainer>
          <DateOptionsContainer containerWidth={containerWidth}>
            {containerWidth <= 680 && (
            <Filter
              actionButtonName='Update'
              activeFilters={activeFilters}
              activeTypeFilters={activeTypeFilters}
              filterTitle='Type'
              handleActionButton={handleActionButton}
              handleChange={handleChangeAnalysis}
              handleChangeType={handleChangeType}
              handleClear={handleClear}
              handleClose={handleClose}
              handleFilter={handleFilter}
              isArrayOfObjects
              isOpen={isFilterOpen}
              isSearchable
              options={options}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              styledPropsWrapper={{
                marginTop: '0px',
              }}
              subheader='Area Selected'
              typeOptions={typeOptions}
            />
            )}
            <DatePicker
              placeholderText='Select Date'
              setStartDate={setDate}
              startDate={selectedDateCal.toDate()}
            />
            <Button
              disabled={!hasHistoryData && !hasAreaSelectedData}
              icon='printer'
              iconOnly
              name='print btn'
              noHover={!hasHistoryData && !hasAreaSelectedData}
              onClick={() => {
                dispatch(setRecoveryRadarActiveModal('PHI-warning'));
                /** KPI tracking */
                dashboardTracker('Recovery Radar', 'Athlete History - Print', 'Print button clicked');
              }}
              styledWrapperProps={{
                height: '35px',
                padding: '3px',
                width: '35px!important',
              }}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <BodyWrapper containerWidth={containerWidth}>
          <BodyHeatMap
            colors={['#3DD6A1', '#B0E4D2', '#D6E095', '#FFE091', '#FFCA63', '#F67361', '#FF3737']}
            data={formattedAthleteHistoryBodyMapData}
            side='front'
            includeLeftRightIndicators
          />
          <BodyHeatMap
            colors={['#3DD6A1', '#B0E4D2', '#D6E095', '#FFE091', '#FFCA63', '#F67361', '#FF3737']}
            side='back'
            includeLeftRightIndicators
            data={formattedAthleteHistoryBodyMapData}
          />
          {!hasHeatMapData && !hasHistoryData && !hasAreaSelectedData && (
          <NoDataModal containerHeight={noDataModalContainerHeight}>
            <ResizeObserver
              onResize={(rect) => {
                setNoDataModalContainerHeight(rect?.height);
              }}
            />
            <ModalTitle>
              {!hasHeatMapData && !hasHistoryData && !hasAreaSelectedData && group && selectedAthlete ? 'No Data Available' : 'Select a Group and Athlete'}
            </ModalTitle>
            <ModalMessage>
              {!hasHeatMapData && !hasHistoryData && !hasAreaSelectedData && group && selectedAthlete
                ? 'There is no data available, please adjust your selection above'
                : 'Select a group and athlete using the dropdown above to get started'}
            </ModalMessage>
          </NoDataModal>
          )}
        </BodyWrapper>
        {isAthleteHistoryDataLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <TablesWrapper>
            {!hasAreaSelectedData ? (
              <TableContainer>
                <Text fontSize='16px' fontWeight={700}>Areas Selected</Text>
                <RRAnalysisHistoryTableNoData
                  containerWidth={containerWidth}
                  dataRows={['']}
                  isHistory
                  rowIsScrollable
                  setColumnHeaderHeight={setColumnHeaderHeight}
                />
              </TableContainer>
            ) : (
              <TableContainer>
                <Text fontSize='16px' fontWeight={700}>Areas Selected</Text>
                <RRAnalysisHistoryTable
                  containerWidth={containerWidth}
                  dataRows={areaSelectedTableData}
                  averageData={areaSelectedAverageData}
                  isHistory
                  rowIsScrollable
                  setColumnHeaderHeight={setColumnHeaderHeight}
                />
              </TableContainer>
            )}

            {!hasHistoryData ? (
              <TableContainer>
                <Text fontSize='16px' fontWeight={700} style={{ marginTop: '30px' }}>
                  28 Day History
                </Text>
                <RRAnalysisHistoryTableNoData
                  containerWidth={containerWidth}
                  dataRows={['', '', '', '', '']}
                  isHistory
                  isHistoryTable
                  rowIsScrollable
                  setColumnHeaderHeight={setColumnHeaderHeight}
                />
              </TableContainer>
            ) : (
              <TableContainer>
                <Text fontSize='16px' fontWeight={700} style={{ marginTop: '30px' }}>
                  28 Day History
                </Text>
                <RRAnalysisHistoryTable
                  containerWidth={containerWidth}
                  dataRows={historyTableData}
                  averageData={historyAverageData}
                  isHistory
                  isHistoryTable
                  rowIsScrollable
                  setColumnHeaderHeight={setColumnHeaderHeight}
                />
              </TableContainer>
            )}
          </TablesWrapper>
        )}
      </DashboardContainer>
    </>
  );
};

export default History;
