import React, { useEffect, useState, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../../../shared/components/Button/Button';
import {
  setIsMetricFiltersPerformanceShowing,
  setSelectedHabits,
} from '../../ducks/habitTrackingDashboardActions';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MaxHabitText = styled('div')`
  border-radius: 100000000px;
  background: #EEE;
  color:  #444;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 16.5px */
  padding: 4px 8px;
  margin-bottom: 16px;
  text-align: center;
  width: 110px;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterCategory = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #444;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const CompletionFilter = () => {
  const isMetricFiltersPerformanceShowing = useSelector((state) => state.habitTrackingDashboard.ui.isMetricFiltersPerformanceShowing);
  const habits = useSelector((state) => state.habitTrackingDashboard.data.habits);

  const dispatch = useDispatch();
  const selectedPerformanceDropdownOptions = useSelector((state) => state.habitTrackingDashboard.data.selectedPerformanceDropdownOptions);

  const [localSelectedHabits, setLocalSelectedHabits] = useState([]);

  const selectInitialHabits = (habitsToSelectFrom) => {
    // Pre-select habits from habits.oldestToNewestHabits
    const newSelectedHabits = habitsToSelectFrom.slice(0, 3).map((habit) => habit.id); // Limit to 3
    setLocalSelectedHabits(newSelectedHabits);
    dispatch(setSelectedHabits(newSelectedHabits));
  };

  useEffect(() => {
    if (habits?.oldestToNewestHabits?.length > 0) {
      selectInitialHabits(habits.oldestToNewestHabits);
    }
  }, [selectedPerformanceDropdownOptions, habits?.oldestToNewestHabits]);

  const handleFilter = () => {
    dashboardTracker('Consistency Coach', 'Completion Overview - Filter', 'Filter clicked');
    dispatch(setIsMetricFiltersPerformanceShowing(!isMetricFiltersPerformanceShowing));
  };

  const handleClose = () => {
    dashboardTracker('Consistency Coach', 'Completion Overview - Filter', 'Filter closed');
    dispatch(setIsMetricFiltersPerformanceShowing(false));
  };

  const handleChange = (habitId) => {
    dashboardTracker('Consistency Coach', 'Completion Overview - Filter', `Filter selected - ${habitId}`);
    let newSelectedHabits;
    if (localSelectedHabits.includes(habitId)) {
      newSelectedHabits = localSelectedHabits.filter((id) => id !== habitId);
    } else {
      newSelectedHabits = [...localSelectedHabits, habitId];
      if (newSelectedHabits.length > 3) {
        newSelectedHabits.shift(); // Ensure only 3 habits are selected
      }
    }
    setLocalSelectedHabits(newSelectedHabits);
  };

  const handleUpdate = () => {
    dashboardTracker('Consistency Coach', 'Completion Overview - Filter', 'Filters updated');

    const selectedHabitIds = localSelectedHabits.slice(0, 3); // Ensure only the first 3 habits are selected

    batch(() => {
      dispatch(setIsMetricFiltersPerformanceShowing(false));
      dispatch(setSelectedHabits(selectedHabitIds));
    });
  };

  const handleClear = () => {
    dashboardTracker('Consistency Coach', 'Completion Overview - Filter', 'Filters cleared');
    setLocalSelectedHabits([]);
  };

  const groupedHabits = habits?.assignedHabits?.reduce((acc, habit) => {
    const category = habit?.category?.name || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(habit);
    return acc;
  }, {});

  const universalHabits = habits?.universalHabits || [];

  const hasHabits = groupedHabits && (Object.keys(groupedHabits).length > 0 || universalHabits.length > 0);

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
        disabled={!hasHabits || !selectedPerformanceDropdownOptions[1]}
      />
      {localSelectedHabits.length > 0 && selectedPerformanceDropdownOptions[1] && (
        <Badge>{localSelectedHabits.length}</Badge>
      )}
      {isMetricFiltersPerformanceShowing && hasHabits && (
        <FilterContainer>
          <FilterHeader>
            Filter
            <Button
              icon='remove'
              iconOnly
              onClick={handleClose}
            />
          </FilterHeader>
          <MaxHabitText>
            3-Habit Maximum
          </MaxHabitText>
          <Filters>
            {groupedHabits && Object.entries(groupedHabits).map(([category, habits]) => (
              <React.Fragment key={category}>
                <FilterCategory>{category}</FilterCategory>
                {habits.map((habit) => (
                  <FilterOption key={habit.id}>
                    <Checkbox
                      color='default'
                      checked={localSelectedHabits.includes(habit.id)}
                      onChange={() => handleChange(habit.id)}
                    />
                    {habit.name.length > 20 ? (
                      <TBTooltip
                        textToDisplay={habit.name}
                        fontSize='16px'
                        textContainerPadding='0px'
                        textContainerMaxWidth='170px'
                        textToHover={habit.name}
                      />
                    ) : (
                      <OptionText>{habit.name}</OptionText>
                    )}
                  </FilterOption>
                ))}
              </React.Fragment>
            ))}
            {universalHabits.length > 0 && (
              <>
                <FilterCategory>Universal Habits</FilterCategory>
                {universalHabits.map((habit) => (
                  <FilterOption key={habit.id}>
                    <Checkbox
                      color='default'
                      checked={localSelectedHabits.includes(habit.id)}
                      onChange={() => handleChange(habit.id)}
                    />
                    {habit.name.length > 20 ? (
                      <TBTooltip
                        textToDisplay={habit.name}
                        fontSize='16px'
                        textContainerPadding='0px'
                        textContainerMaxWidth='170px'
                        textToHover={habit.name}
                      />
                    ) : (
                      <OptionText>{habit.name}</OptionText>
                    )}
                  </FilterOption>
                ))}
              </>
            )}
          </Filters>
          <FilterBottom>
            <Button
              cta='Clear'
              rounded
              onClick={handleClear}
            />
            <Button
              cta='Update'
              disabled={localSelectedHabits.length === 0}
              rounded
              onClick={handleUpdate}
            />
          </FilterBottom>
        </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default CompletionFilter;
