import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';
import IcomoonReact from 'icomoon-react';
import { useDispatch, useSelector } from 'react-redux';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';
import { setIsGroupFilterShowing, setSelectedGroupHabits } from '../../ducks/habitTrackingDashboardActions';
import Button from '../../../../shared/components/Button/Button';

const Styles = styled.div`
  background: #FFFFFF;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;

  .paginationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .paginationButton {
    font-family: 'Nunito Sans';
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border: none;
  }

  & .sticky {
    overflow-x: auto;
    margin: 25px 0px;
    width: 100%;

    tfoot {
      td:first-child {
        position: sticky;
        left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #BDBDBD1A;
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    min-width: 270px;
    height: 200px;
    padding: 12px;
    border-top: 1px solid #E0E0E0;
  }

  .noHoverCategoryColumnTitleCell:nth-child(2) {
    border-top-left-radius: 8px;
    border-left: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
  }

  .noHoverCategoryColumnTitleCell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
  }

  .rawValueTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: normal;
    background-color: #BDBDBD1A;
    color: #444444;
    text-align: left;
    min-width: 115px;
    max-width: 115px;
    cursor: pointer;
  }

  // Athlete name column sticky styling
  .athleteNameTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #444444;
    min-width: 180px;
    max-width: 180px;
    position: sticky;
    left: 0;
    z-index: 51;
    background-color: #f8f8f8;
    border-left: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    top: 0;
  }

  .footerCells {
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: normal;
    text-align: left;
    color: #444444;
  }

  td:has(div):has(.deviation-alert-red) {
    background-color: #FFCDCD;
  }

  td:has(div):has(.deviation-alert-green) {
    background-color: #CFF5E8;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 14px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #BDBDBD1A;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 1px solid #E0E0E0;
          height: 61px;
          min-height: 61px;
          border-bottom: none;
          background: #FCFCFC;
          border-bottom-left-radius: 8px;
        }
      }

      td:first-child {
        color: #444444;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        font-family: 'Nunito Sans';
      }

      tr:last-child {
        display: none;
      }
    }
  }
`;

const ThresholdContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: none;
`;

const ThresholdTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #444444;
  position: relative;
  margin-bottom: 8px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #444444;
    position: absolute;
    right: 35px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const SetThresholdText = styled.div`
  color: #444;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: 'Nunito Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.39px;
  text-decoration-line: underline;
  transition: color 0.2s;
  cursor: pointer;
  align-self: center;
  :hover {
    color: #000;
  }
`;

const ThresholdInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ThresholdInput = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
  overflow: hidden;

  .symbol {
    background-color: ${(props) => (props.type === 'lower' ? '#CFF5E8' : '#FFCDCD')};
    padding: 5px;
    font-size: 16px;
    color: #444444;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 1px solid #ddd;
    flex-shrink: 0; /* Prevent symbol area from shrinking */
  }

  input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 16px;
    color: #444444;
    text-align: right;
  }

  input:focus {
    border: none;
    outline: none;
  }

  .percent {
    padding: 5px;
    font-size: 16px;
    color: #444444;
    border-left: 1px solid #ddd;
    flex-shrink: 0; /* Prevent percent sign from shrinking */
  }
`;

const HabitRemoveButton = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #444444;
  transition: color 0.2s;
  :hover {
    color: #000;
  }
`;

const ClearThresholdButton = styled.button`
  border-radius: 99999997952px;
  border: none;
  background: #eee;
  color: #444444;
  cursor: pointer;
  text-decoration: underline;
  align-self: flex-start;
  margin-top: 10px;
  display: flex;
  padding: 2px 8px;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 13.5px */
  letter-spacing: -0.09px;
  text-decoration-line: underline;
  &:hover {
    color: #000;
  }
`;

// Utility function to generate a safe key for habit names
const generateSafeKey = (habitName) => habitName.replace(/[^a-zA-Z0-9]/g, '_');

// The Threshold component handles setting thresholds for habits
const Threshold = ({
  habit, thresholds, setThresholds, clearThreshold, isOpen, setIsOpen,
}) => {
  const safeHabitKey = generateSafeKey(habit);

  const handleThresholdChange = (key, value) => {
    if (value === '') {
      // Allow empty input
      setThresholds((prev) => ({
        ...prev,
        [safeHabitKey]: {
          ...prev[safeHabitKey],
          [key]: '',
        },
      }));
      return;
    }

    // Only allow numeric input between 0 and 100
    const numValue = parseFloat(value);
    if (isNaN(numValue) || numValue < 0 || numValue > 100) return;

    setThresholds((prev) => ({
      ...prev,
      [safeHabitKey]: {
        ...prev[safeHabitKey],
        [key]: numValue,
      },
    }));
  };

  useEffect(() => {
    if (isOpen && !thresholds[safeHabitKey]) {
      setThresholds((prev) => ({
        ...prev,
        [safeHabitKey]: {
          lower: '',
          upper: '',
        },
      }));
    }
  }, [isOpen, habit, setThresholds, thresholds, safeHabitKey]);

  return (
    <ThresholdContainer>
      {isOpen && (
        <ThresholdTitle>
          <span>Thresholds</span>
          <Button iconOnly icon='remove' onClick={() => setIsOpen(false)} />
        </ThresholdTitle>
      )}
      {isOpen ? (
        <ThresholdInputs>
          <ThresholdInput type='lower'>
            <div className='symbol'>{'>'}</div>
            <input
              type='text'
              inputMode='decimal'
              pattern='[0-9]*'
              value={thresholds[safeHabitKey]?.lower ?? ''}
              onChange={(e) => handleThresholdChange('lower', e.target.value)}
              placeholder='0-100'
            />
            <div className='percent'>%</div>
          </ThresholdInput>
          <ThresholdInput type='upper'>
            <div className='symbol'>{'<'}</div>
            <input
              type='text'
              inputMode='decimal'
              pattern='[0-9]*'
              value={thresholds[safeHabitKey]?.upper ?? ''}
              onChange={(e) => handleThresholdChange('upper', e.target.value)}
              placeholder='0-100'
            />
            <div className='percent'>%</div>
          </ThresholdInput>
          <ClearThresholdButton onClick={() => clearThreshold(habit)}>
            Clear thresholds
          </ClearThresholdButton>
        </ThresholdInputs>
      ) : (
        <SetThresholdText onClick={() => setIsOpen(true)}>Set Threshold</SetThresholdText>
      )}
    </ThresholdContainer>
  );
};
const Table = ({
  columns,
  data,
  getCellProps,
  thresholds,
  openThresholds,
  handleAddHabitClick,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 30 },
    },
    useSticky,
    useSortBy,
    usePagination,
  );

  const selectedGroupHabits = useSelector((state) => state.habitTrackingDashboard.data.selectedGroupHabits);
  const habits = useSelector((state) => state.habitTrackingDashboard.data.habits);

  const totalHabitsLength = Object.entries(habits)
    .filter(([key]) => key !== 'oldestToNewestHabits')
    .reduce((sum, [, currentArray]) => sum + currentArray.length, 0);

  const selectedGroupHabitsLength = selectedGroupHabits.length;

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <button
          className='paginationButton'
          type='button'
          key={i}
          onClick={() => gotoPage(i)}
          style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
        >
          {i + 1}
        </button>,
      );
    }
    return buttons;
  };

  return (
    <>
      <div id='reportTable' style={{ display: 'flex', zIndex: 0 }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.className}
                    style={column.style}
                    key={column.id}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc ? (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='down-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        ) : (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='up-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    const habitName = cell.column.id.split('-')[0]; // Get the habit name
                    const safeHabitKey = generateSafeKey(habitName); // Generate a safe key
                    const value = parseFloat(cell.value) || 0; // Parse cell value to a number
                    const habitThresholds = thresholds[safeHabitKey] || { lower: 0, upper: 0 };
                    // Check if the thresholds for this habit are open and applicable
                    const shouldHighlight = openThresholds[safeHabitKey];

                    // Determine the color based on thresholds
                    const highlightClass = shouldHighlight
                      ? value >= habitThresholds.lower && (habitThresholds.lower !== '')// If value exceeds upper threshold, mark as green
                        ? 'deviation-alert-green'
                        : value <= habitThresholds.upper && (habitThresholds.upper !== '')// If value is below lower threshold, mark as red
                          ? 'deviation-alert-red'
                          : ''
                      : '';

                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            style: { height: '100%' },
                          },
                          getCellProps(cell),
                        ])}
                        key={cell.column.id}
                      >
                        {/* Apply the highlight class based on the calculated thresholds */}
                        <div className={highlightClass}>{cell.render('Cell')}</div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()} key={group.id}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()} key={column.id}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {selectedGroupHabitsLength < totalHabitsLength && selectedGroupHabitsLength < 10 && (
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              background: '#5F8BDC1A',
              color: '#5F8BDC',
              border: '1px dashed #5F8BDC',
              borderRadius: '5px',
              width: '250px',
              minWidth: '150px',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'NunitoSans',
              fontSize: '16px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => handleAddHabitClick()}
          >
            <span>+Add Habit</span>
          </div>
        )}
      </div>
      {pageCount > 1 && (
        <div className='paginationContainer'>
          <div className='pagination'>
            <button className='paginationButton' type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            {renderPageButtons()}
            <button className='paginationButton' type='button' onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const GroupAnalysisTable = ({ containerWidth, dataRows }) => {
  const dispatch = useDispatch();
  const [thresholds, setThresholds] = useState({});
  const [openThresholds, setOpenThresholds] = useState({});
  const selectedGroupHabits = useSelector((state) => state.habitTrackingDashboard.data.selectedGroupHabits);

  const handleAddHabitClick = () => {
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', '+Add Habit clicked');
    dispatch(setIsGroupFilterShowing(true));
  };

  // Clear thresholds for a specific habit
  const clearThreshold = (habit) => {
    const safeHabitKey = generateSafeKey(habit); // Generate safe key
    setThresholds((prev) => ({
      ...prev,
      [safeHabitKey]: {
        lower: '',
        upper: '',
      },
    }));
  };
  useEffect(() => {
    if (selectedGroupHabits.length > 0 && dataRows.length > 0 && columns.length > 0) {
      // Find the first habit column, skipping the athlete "Name" column (assumed to have id 'name')
      const habitColumn = columns.find((col) => col.id !== 'name' && col.id !== undefined);

      // Check if we found a valid habit column
      if (habitColumn) {
        const habitName = habitColumn.id.split('-')[0]; // Extract the habit name from the column id
        const safeHabitKey = generateSafeKey(habitName); // Generate a safe key for the habit

        // Open the threshold editor for the first habit column
        setOpenThresholds((prev) => ({
          ...prev,
          [safeHabitKey]: true,
        }));

        // Set default thresholds for the habit
        setThresholds((prev) => ({
          ...prev,
          [safeHabitKey]: { lower: prev[safeHabitKey]?.lower ?? 50, upper: prev[safeHabitKey]?.upper ?? 50 },
        }));
      }
    }
  }, [selectedGroupHabits, dataRows, columns]);

  // Helper function to dynamically generate columns based on selected habits
  const habitColumns = (habit, index) => {
    const safeHabitKey = generateSafeKey(habit); // Generate safe key
    const isHabitSelected = selectedGroupHabits.some((selectedHabit) => selectedHabit.name === habit);

    if (!isHabitSelected) {
      return []; // Return an empty array if the habit is not selected
    }

    return [
      {
        Header: (
          <div style={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%' }}>
            <div style={{ alignSelf: 'center' }}>
              <Threshold
                habit={habit}
                thresholds={thresholds}
                setThresholds={setThresholds}
                clearThreshold={clearThreshold}
                isOpen={!!openThresholds[safeHabitKey]}
                setIsOpen={(open) => setOpenThresholds((prev) => ({ ...prev, [safeHabitKey]: open }))}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
              <div>{habit}</div>
              <HabitRemoveButton
                iconOnly
                icon='remove'
                onClick={() => {
                  const updatedHabits = selectedGroupHabits.filter((currentHabit) => {
                    const shouldInclude = currentHabit.name !== habit; // Dynamically compare with the current habit
                    return shouldInclude;
                  });
                  dispatch(setSelectedGroupHabits(updatedHabits));
                }}
                style={{ background: 'none' }}
              />
            </div>
          </div>
        ),
        Footer: () => null,
        id: safeHabitKey,
        className: 'noHoverCategoryColumnTitleCell',
        columns: [
          {
            Header: 'This Week',
            accessor: (row) => {
              const habitData = row?.results?.[index]?.[habit];
              return habitData && habitData[0] ? habitData[0].value : '-';
            },
            Cell: ({ value }) => <div>{value || '-'}</div>,
            id: `${safeHabitKey}-this-week`,
            className: 'rawValueTitle',
            style: {
              minWidth: '90px',
              maxWidth: '90px',
              textAlign: 'left',
              fontFamily: 'Nunito Sans',
              fontSize: '16px',
              fontWeight: 'normal',
              color: '#444444',
            },
          },
          {
            Header: '4 Week Average',
            accessor: (row) => {
              const habitData = row?.results?.[index]?.[habit];
              return habitData && habitData[1] ? habitData[1].value : '-';
            },
            Cell: ({ value }) => <div>{value || '-'}</div>,
            id: `${safeHabitKey}-4-week-average`,
            className: 'rawValueTitle',
            style: {
              minWidth: '90px',
              maxWidth: '90px',
              textAlign: 'left',
              fontFamily: 'Nunito Sans',
              fontSize: '14px',
              fontWeight: 'normal',
              color: '#444444',
            },
          },
        ],
      },
    ];
  };

  // Generate all habit columns for the table
  const allHabitColumns = dataRows?.[0]?.results?.flatMap((result, index) => (result ? habitColumns(Object.keys(result)?.[0], index) : [])) || [];

  // Athlete name column
  const athleteNameColumn = [
    {
      Header: () => null,
      Footer: () => null,
      id: 'name',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Name',
          accessor: 'athleteName',
          className: 'athleteNameTitle',
          style: {
            borderRight: '1px solid #E0E0E0',
            height: '58px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
          Cell: ({ row }) => (
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
              <div style={{
                borderRadius: '50%', height: '35px', marginRight: '5px', overflow: 'hidden', width: '35px',
              }}
              >
                <img alt={row.original.athleteName} src={row.original.pic} style={{ height: '100%' }} />
              </div>
              <div>{row.original.athleteName}</div>
            </div>
          ),
        },
      ],
    },
  ];

  // Combine athlete name and habit columns into the final table columns
  const columns = [...athleteNameColumn, ...allHabitColumns];

  return (
    <Styles>
      <Table
        containerWidth={containerWidth}
        columns={columns}
        data={dataRows || []}
        getCellProps={(cellInfo) => ({
          style: {
            backgroundColor: 'FFFFFFFF',
            color: 'FF444444',
            fillColor: 'FFFFFFFF',
            fontColor: 'FF444444',
          },
        })}
        thresholds={thresholds}
        openThresholds={openThresholds}
        handleAddHabitClick={handleAddHabitClick}
      />
    </Styles>
  );
};

export default GroupAnalysisTable;
