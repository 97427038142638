/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import {
  BoxShadow,
  ColorPrimary,
  ColorWhite,
  Nunito,
} from '../../../shared/GlobalStyles';
import Toggle from '../../../shared/_TBComponents/Toggle';
import { setRegionStatusToggle } from '../ducks/RecoveryRadarActions';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: ${ColorWhite};
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  position: relative;
`;

const Container = styled('div')`
  font-family: ${Nunito};
  font-size: 16px;
`;

const Header = styled('div')`
  display: flex;
  gap: 10px;
`;

const HeaderText = styled('div')`
  font-weight: 500;
  color: ${ColorPrimary};
`;

const Body = styled('div')`
  color: #9E9E9E;
`;

const Settings = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const regionStatusToggle = useSelector((state) => state.recoveryRadar.data.regionStatusToggle);

  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setRegionStatusToggle(accountCode, { heatMapTagsEnabled: !regionStatusToggle }));
    // KPI tracking
    dashboardTracker('Recovery Radar', 'Settings', 'Region & Status setting toggled');
  };

  return (
    <DashboardContainer>
      <Container>
        <Header>
          <Toggle
            defaultChecked={regionStatusToggle}
            onChange={handleToggle}
            width='50px'
          />
          <HeaderText>
            Include region and status
          </HeaderText>
        </Header>
        <Body>
          Give athletes the ability to select an option for both region and status.
          &#34;Region&#34; refers to the location of the pain or soreness and
          &#34;Status&#34; is the athlete&#39;s current injury status.
        </Body>
      </Container>
    </DashboardContainer>
  );
};

export default Settings;
