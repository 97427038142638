/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';

import HeaderText from '../../shared/components/HeaderText/HeaderText';
import Settings from './components/Settings';
import {
  fetchGroups,
  fetchViewedFeatures,
  setOnboardingModalOpen,
  setRecoveryRadarView,
  setViewedFeature,
} from './ducks/RecoveryRadarActions';
import { Nunito } from '../../shared/GlobalStyles';
import Overview from './components/Overview';
import Analysis from './components/Analysis';
import History from './components/History';
import RecoveryRadarModal from './RecoveryRadarModal';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';
import OnboardingModal from '../../shared/_TBComponents/OnboardingModal';
import { createViewedFeaturePayload, FEATURES } from '../../shared/utils/onboardingStatus';
// Temporary onboarding images
import onboardingImageOne from '../../shared/images/ht-onboarding-one.png';
import onboardingImageTwo from '../../shared/images/ht-onboarding-two.png';
import onboardingImageThree from '../../shared/images/ht-onboarding-three.png';
import onboardingImageFour from '../../shared/images/ht-onboarding-four.png';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1450px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;

  h1 {
    padding-bottom: 8px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: ${Nunito};
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const RecoveryRadar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isOnboardingModalShowing = useSelector((state) => state.recoveryRadar.ui.isOnboardingModalShowing);
  const recoveryRadarView = useSelector((state) => state.recoveryRadar.ui.recoveryRadarView);

  // UPDATE CONTENT & IMAGES
  const content = [
    {
      image: onboardingImageOne,
      title: 'Create & Categorize Habits',
      description: 'To begin habit tracking inside TeamBuildr, start out by creating habits inside the web app so they appear for your athletes. Habits need a category assigned in order to view the collected data in Group & Individual Analysis.',
      gradientType: 'primary',
    },
    {
      image: onboardingImageTwo,
      title: 'Completion Overview',
      description: 'In the Completion Overview tab, select a group from the dropdown menu in the top left corner to view the collected data, and choose a week range in the top right. It is possible to go back at a max of 12 weeks from a selected day. The date selector will automatically detect the week range you select. Collected data starts on Monday and ends on Sunday.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageThree,
      title: 'Group Analysis',
      description: 'The Group Analysis tab allows you to view a table of athletes and their assigned habit data, either individually or as a full group. You can select up to 10 habits in this view by filtering them, the filter can be found to the right of the selected group or athlete. In this view, you are also able to dynamically set thresholds for each habit. Thresholds will be removed if the browser is closed or you move out of habit tracking inside the same browser window.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageFour,
      title: 'Data Visibility',
      description: 'Data will not appear for habits that do not have a category assigned to it. If you delete a category, all associated habits will still be there, but they will need to be assigned a new category in order to view it in the Group and Individual analysis tabs.',
      gradientType: 'primary',
    },
  ];

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchGroups(currentUser.accountCode));
    }
  }, [currentUser]);

  // Check if the user has viewed the dashboard before to display onboarding modal or not
  // useEffect(() => {
  //   dispatch(fetchViewedFeatures());
  // }, []);

  const handleMenuClick = (view) => {
    if (view !== recoveryRadarView) {
      dispatch(setRecoveryRadarView(view));
      // KPI tracking
      let title;
      switch (view) {
        case 'analysis':
          title = 'Group Analysis';
          break;
        case 'history':
          title = 'Athlete History';
          break;
        case 'settings':
          title = 'Settings';
          break;
        default:
          title = 'Overview';
      }
      dashboardTracker('Recovery Radar', title, 'Menu item clicked');
    }
  };

  const handleClose = () => {
    const viewedFeaturePayload = createViewedFeaturePayload(currentUser.accountCode, currentUser.id, FEATURES.RECOVERY_RADAR);
    batch(() => {
      dispatch(setOnboardingModalOpen(false));
      dispatch(setViewedFeature(viewedFeaturePayload));
    });
  };

  return (
    <Container>
      <Helmet>
        <title>Recovery Radar | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <DashboardHeader>
          <HeaderText
            fontWeight='300'
            letterSpacing='normal'
          >
            Recovery Radar
          </HeaderText>
          <MenuContainer>
            <MenuItem
              isActive={recoveryRadarView === 'overview'}
              onClick={() => handleMenuClick('overview')}
            >
              Overview
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'analysis'}
              onClick={() => handleMenuClick('analysis')}
            >
              Group Analysis
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'history'}
              onClick={() => handleMenuClick('history')}
            >
              Athlete History
            </MenuItem>
            <MenuItem
              isActive={recoveryRadarView === 'settings'}
              onClick={() => handleMenuClick('settings')}
            >
              Settings
            </MenuItem>
          </MenuContainer>
        </DashboardHeader>
        {recoveryRadarView === 'overview' && (
          <Overview />
        )}
        {recoveryRadarView === 'analysis' && (
          <Analysis />
        )}
        {recoveryRadarView === 'history' && (
          <History />
        )}
        {recoveryRadarView === 'settings' && (
          <Settings />
        )}
      </PageWrapper>
      <RecoveryRadarModal />
      <OnboardingModal
        badgeText='GET STARTED'
        content={content}
        isActive={isOnboardingModalShowing}
        maxHeight={null}
        minHeight={null}
        onClose={handleClose}
        onPrimaryAction={handleClose}
        onSecondaryAction={handleClose}
        primaryButtonText='GET STARTED'
        showSingleButton
      />
    </Container>
  );
};

export default RecoveryRadar;
